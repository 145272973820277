




























































import Vue from 'vue'
import Component from 'vue-class-component'
import { apiAgentApplyLists , apiAgentApplyMark } from '@/api/user'
import AreaSelect from '@/components/area-select.vue'
@Component({
	components: {
		AreaSelect
	}
})
export default class ApplicationList extends Vue {
	// 搜索表单数据
	form = {
		applicationArea: '',
		look_time: '',
		keywords: '',
		page_no: 1,
		page_size: 10,
		create_start_time: 0,
		create_end_time: 0,
		province:'',
		city:'',
		district:''
	};

	// 数据表格
	tableData = [];
	total = 0
	// 查看状态选项
	viewStatuses = [
		{ value: 1, label: '已联系' },
		{ value: 0, label: '待联系' }
	];
	registerTime : any = null
	changeTime() {
		if (this.registerTime) {
			this.form.create_start_time = new Date(this.registerTime[0]).getTime() / 1000
			this.form.create_end_time = new Date(this.registerTime[1]).getTime() / 1000
		}else{
			this.form.create_start_time = 0
			this.form.create_end_time = 0
		}
		this.getAgentApplyLists()
	}
	// 搜索操作
	onSearch() {
		this.getAgentApplyLists()
	}

	// 重置操作
	onReset() {
		this.form = {
			applicationArea: '',
			look_time: '',
			keywords: '',
			page_no: 1,
			page_size: 10,
			create_start_time: 0,
			create_end_time: 0,
			province:'',
			city:'',
			district:''
		}
		this.getAgentApplyLists()
	}

	// 刷新操作
	onRefresh() {
		this.$router.go(0)
	}

	// 查看详情
	viewDetails(row : any) {
		apiAgentApplyMark({
			id: row.id
		}).then(()=>{
			this.getAgentApplyLists()
		})
	}
	getAgentApplyLists() {
		apiAgentApplyLists(this.form).then((res) => {
			this.tableData = res.lists
			this.total = res.count
		})
	}
	// 分页切换
	handlePageChange(page : number) {
		this.form.page_no = page
		this.getAgentApplyLists()
	}
	created() {
		this.getAgentApplyLists()
	}
}
